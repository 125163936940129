import React from 'react';
import {Checkbox, Text} from 'wix-ui-tpa/cssVars';
import {classes} from './BillingDetails/BillingDetails.st.css';
import {useBillingData} from './WithBillingData/WithBillingData';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {SPECS} from '../../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';

export enum BillingSameAsShippingCheckboxDataHook {
  billingSameAsShippingToggle = 'BillingSameAsShippingCheckbox.billingSameAsShippingToggle',
  billingSameAsShippingLabel = 'BillingSameAsShippingCheckbox.billingSameAsShippingLabel',
}

export const BillingSameAsShippingCheckbox = () => {
  const {experiments} = useExperiments();
  const {setBillingSameAsShipping, billingSameAsShipping} = useBillingData();

  const localeKeys = useLocaleKeys();
  const getText = (): string => {
    return experiments.enabled(SPECS.DeliveryOverShippingText)
      ? localeKeys.checkout.billing_information.billing_same_as_delivery_label()
      : localeKeys.checkout.billing_information.billing_same_as_shipping_label();
  };
  return (
    <Checkbox
      className={classes.sameAsShippingToggle}
      data-hook={BillingSameAsShippingCheckboxDataHook.billingSameAsShippingToggle}
      label={<Text data-hook={BillingSameAsShippingCheckboxDataHook.billingSameAsShippingLabel}>{getText()}</Text>}
      checked={billingSameAsShipping}
      onChange={({checked}) => setBillingSameAsShipping(checked)}
    />
  );
};
